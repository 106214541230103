import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function BreadCrumb() {
  const { t } = useTranslation('language');
  const path = window.location.pathname;
  const parts = path.split('/').filter((part) => part !== '');

  const breadcrumbs = parts.map((part, index) => {
    const routeTo = `/${parts.slice(0, index + 1).join('/')}`;
    return (
      <li key={part}>
        <Link to={routeTo}>{t(part)}</Link>
      </li>
    );
  });
  return (
    <div className="breadcrumbs text-sm">
      <ul>
        {parts.length > 0 && (
          <li>
            <Link to={'/'}>Нүүр хуудас</Link>
          </li>
        )}
        {breadcrumbs}
      </ul>
    </div>
  );
}

export default BreadCrumb;
