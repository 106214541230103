import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_ORDER_BY_CODE } from 'graphql/query';
import Header from 'layout/Header';
import { useParams, useNavigate } from 'react-router-dom';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { IDocument, IOffer, ITask } from 'types';
import { useTranslation } from 'react-i18next';
import { IoMdAttach } from 'react-icons/io';
import { FiEye } from 'react-icons/fi';
import { MdOutlineFileDownload } from 'react-icons/md';
import { downloadFile, formatWithCommas } from 'utils';
import { TECHNICAL_CONDITIONS } from 'constants/type';
import { GET_TASK_OFFERS } from 'graphql/query/offer';
import moment from 'moment';
import License from 'components/License';
import { IoArrowBack } from 'react-icons/io5';
import { PAGE_ORDER } from 'constants/pages';

const Index = () => {
  let history = useNavigate();
  const { t } = useTranslation('language');
  const { code } = useParams<{ code: string }>();

  const [getTaskOffers, { data: taskOffer, loading: offering, refetch }] =
    useLazyQuery(GET_TASK_OFFERS);

  const { data, loading } = useQuery(GET_ORDER_BY_CODE, {
    skip: !code,
    variables: { code },
    onCompleted: (data) => {
      let task = data.getOrderByCode.tasks?.find(
        (task: ITask) => task.type === TECHNICAL_CONDITIONS,
      );
      if (task) {
        getTaskOffers({
          variables: { id: task.id },
        });
      }
    },
    fetchPolicy: 'network-only',
  });

  if (loading || offering)
    return (
      <div className="h-screen flex place-items-center justify-center ">
        <AiOutlineLoading3Quarters className="animate-spin  h-12 w-12 text-gray-500  " />
      </div>
    );

  const acceptOffer = (offer: IOffer) => {
    history(`/order/charge/${offer.id}`);
  };

  const renderDocument = (document: IDocument, index: number) => (
    <div
      key={index}
      className={`flex  p-2  border border-gray-300 rounded-lg place-items-center   justify-between  mb-2`}
    >
      <div className="flex place-items-center gap-2">
        <IoMdAttach className="text-gray-500" />
      </div>
      <p className="text-sm mx-2 font-normal w-full truncate text-ellipsis overflow-hidden ">
        {document.name}
      </p>
      <div className="flex gap-2">
        <FiEye
          className=" cursor-pointer text-lg text-gray-500"
          onClick={() => window.open(document.path)}
        />
        <MdOutlineFileDownload
          className=" cursor-pointer text-lg text-gray-500"
          onClick={() => downloadFile(document.path)}
        />
      </div>
    </div>
  );

  const renderOffer = (offer: IOffer) => {
    return (
      <div className="p-4 pt-2 border border-gray-300 rounded-lg mt-3">
        <div className="w-full">
          <div className="flex place-content-between text-xs mt-2 text-gray-500">
            <span>Үнийн санал өгсөн огноо:</span>
            <span className="font-semibold">{moment(offer.createdAt).format('YYYY-MM-DD')}</span>
          </div>
          <div className="flex place-content-between text-xs mt-2 text-gray-500">
            <span>Үнийн санал:</span>
            <span className="font-semibold">{formatWithCommas(offer.price)} ₮</span>
          </div>
          <div className="flex place-content-between text-xs mt-2 text-gray-500">
            <span>Гүйцэтгэх ажил:</span>
            <span className="font-semibold">
              {offer.types.map((type: string, index: number) => (
                <div key={index}>{t(type)}</div>
              ))}
            </span>
          </div>
          <License offer={offer} onAccept={acceptOffer} />
        </div>
      </div>
    );
  };

  const renderContent = () => (
    <div className="overflow-y-scroll min-h-full mb-4 ">
      <div className="flex gap-2 pt-3 pl-2 ">
        <IoArrowBack
          className=" cursor-pointer h-6 w-6 text-[#164789] "
          onClick={() => history(PAGE_ORDER)}
        />
        <span className="text-base  font-semibold text-[#164789]  ">Үнийн саналууд</span>
      </div>
      {taskOffer?.getTaskOffers?.map((offer: IOffer) => renderOffer(offer))}
    </div>
  );

  return (
    <div className="h-full">
      <Header />
      <section className="pt-14 pb-0 px-0 max-w-3xl h-max  mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
        <div className="p-4">{renderContent()}</div>
      </section>
    </div>
  );
};

export default Index;
