import { PAGE_AUTH } from 'constants/pages';
import Header from 'layout/Header';
import { useNavigate } from 'react-router-dom';
import HomeContent from 'templates/HomeContent';

const Index = () => {
  let history = useNavigate();

  const goFlow = () => {
    history(PAGE_AUTH);
  };

  const goSecondFlow = () => {
    const newWindow = window.open('https://partner.epower.mn', '_blank');
    if (newWindow) {
      newWindow.focus();
    }
  };

  return (
    <>
      <Header />
      <HomeContent
        goFlow={goFlow}
        buttonText="Ажил захиалагч"
        goSecondFlow={goSecondFlow}
        secondButtonText="Ажил гүйцэтгэгч"
      />
    </>
  );
};

export default Index;
