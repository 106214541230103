import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerSchema } from 'components/resolvers';
import { useEffect, useContext, useState } from 'react';
import FormTemplate from 'templates/FormTemplate';
import ControlledInput from 'components/ControlledForms/ControlledInput';
import ControlledFourDigits from 'components/ControlledForms/ControlledFourDigits';
import { useNavigate } from 'react-router-dom';
import FormInputTemplate from 'templates/FormInputTemplate';
import FormBoxTemplate from 'templates/FormBoxTemplate';
import { useMutation } from '@apollo/client';
import { GET_VERIFY, REGISTER_PASSWORD, VERIFY } from 'graphql/mutation';
import { AuthContext, useNotifications } from 'providers';
import { PAGE_INDEX } from 'constants/pages';

const Index = () => {
  let history = useNavigate();
  const { authenticate } = useContext(AuthContext);
  const { addNotification } = useNotifications();
  const [time, setTime] = useState(60000);

  const { control, handleSubmit, setValue, watch, setError } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(registerSchema) as any,
    defaultValues: {
      step: 1,
      type: 1,
    },
  });

  const { step, phone, pin, verifyToken, start } = watch();

  const [getVerify, { loading }] = useMutation(GET_VERIFY, {
    onCompleted: (data) => {
      setValue('step', 2);
      setValue('verifyToken', data.getVerify.token);
      setValue('start', true);
    },
    onError: (error) => {
      setError('phone', { type: 'server', message: error.message });
    },
  });

  const [verify, { loading: loadingVerify }] = useMutation(VERIFY, {
    onCompleted: (data) => {
      setValue('step', 3);
    },
    onError: (error) => {
      setError('pin', { type: 'server', message: error.message });
    },
  });

  const [registerPassword, { loading: loadingPassword }] = useMutation(REGISTER_PASSWORD, {
    onCompleted: (data) => {
      authenticate(data.registerPassword.token, () => {
        addNotification('Бүртгэл амжилттай!', 'success');
        history('/technical-conditions');
      });
      setValue('step', 3);
    },
    onError: (error) => {
      setError('password', { type: 'server', message: error.message });
    },
  });

  const onSubmit = (data: FieldValues) => {
    if (step === 1) {
      getVerify({ variables: { type: 'R', phone } });
    }
    if (step === 3) {
      registerPassword({
        variables: { token: verifyToken, password: data.password, firstName: data.firstName },
      });
    }
  };

  useEffect(() => {
    if (pin && pin.length === 4) {
      verify({ variables: { token: verifyToken, code: pin } });
    }
  }, [pin, setValue, verify, verifyToken]);

  useEffect(() => {
    let interval: any = null;

    if (start) {
      if (time < 1) {
        setValue('start', false);
      } else {
        interval = setInterval(() => {
          setTime((preTime) => preTime - 10);
        }, 10);
      }
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [start, time, setValue]);

  const goBackStep = () => {
    if (step === 1) {
      history(PAGE_INDEX);
    }
    if (step === 2) {
      setValue('step', 1);
    }
  };

  const onTry = () => {
    if (start) return;
    getVerify({ variables: { type: 'R', phone } });
    setTime(60000);
    setValue('start', true);
  };

  return (
    <FormTemplate
      totalSteps={4}
      handleSubmit={handleSubmit}
      activeStep={step}
      onSubmit={onSubmit}
      onBack={goBackStep}
      loading={loading || loadingVerify || loadingPassword}
      disabled={loading || loadingVerify || loadingPassword}
      onBackHide={step === 3 || step === 2}
    >
      <FormInputTemplate
        isHide={step === 1}
        formTypeName="Ажил захиалагчийн бүртгэл"
        headerText="Таны утасны дугаар"
        headerDescription="Энэ бол бүртгэлтэй компаниудад харагдах утас юм. Та өөрийн хувийн мэдээлэл хэсэгт солих боломжтой."
      >
        <FormBoxTemplate>
          <ControlledInput
            control={control}
            text="Утасны дугаар оруулна уу"
            name="phone"
            inputMode="numeric"
            type="text"
          />
        </FormBoxTemplate>
      </FormInputTemplate>

      <FormInputTemplate
        isHide={step === 2}
        formTypeName="Ажил захиалагчийн бүртгэл"
        headerText="Утасны дугаар баталгаажуулалт"
        headerDescription="Бидний саяхан илгээсэн 4 оронтой кодыг оруулна уу"
      >
        <FormBoxTemplate>
          <div className="text-sm w-full pb-4   text-gray-800  text-start">
            <span> +(976) {phone}</span>
          </div>
          <ControlledFourDigits control={control} loading={loadingVerify} name="pin" />
          <div
            onClick={() => onTry()}
            className={` ${
              start ? ' text-gray-400 ' : ' inline p-2  bg-gray-300 rounded-lg text-gray-700'
            } text-sm   cursor-pointer mb-2 text-start`}
          >
            <span>Дахин авах</span>
            <span>
              {time !== 0 && (
                <span className="text-gray1">
                  ({('' + Math.floor((time / 1000) % 60)).slice(-2)})
                </span>
              )}{' '}
            </span>
          </div>
        </FormBoxTemplate>
      </FormInputTemplate>

      <FormInputTemplate
        isHide={step === 3}
        formTypeName="Ажил захиалагчийн бүртгэл"
        headerText="Баталгаажуулалт"
      >
        <FormBoxTemplate>
          <ControlledInput
            control={control}
            text="Хэрэглэгчийн нэр оруулна уу"
            name="firstName"
            inputMode="text"
            type="text"
          />
          <ControlledInput
            control={control}
            text="Нууц үг оруулна уу"
            name="password"
            inputMode="text"
            type="password"
          />
          <ControlledInput
            control={control}
            text="Давтан оруулна уу"
            name="passwordRepeat"
            inputMode="text"
            type="password"
          />
        </FormBoxTemplate>
      </FormInputTemplate>
    </FormTemplate>
  );
};

export default Index;
