import { gql } from '@apollo/client';

export const ON_UPDATE_TASK = gql`
  subscription onUpdateTask($user: ID!) {
    onUpdateTask(user: $user) {
      id
      event
      user
      task {
        id
        type
        state
        completedAt
        documents {
          id
          name
          path
        }
      }
    }
  }
`;
