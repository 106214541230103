import { ITransaction } from 'types';

export function formatWithCommas(number: number): string {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function extractBase64Content(base64Data: string) {
  const matches = base64Data.match(/^data:(.*?);base64,(.*)$/);

  if (!matches || matches.length !== 3) {
    throw new Error('Invalid base64 string');
  }

  const contentType = matches[1];
  const base64Content = matches[2];

  return { contentType, base64Content };
}

export async function uploadToS3(presignedUrl: string, base64Data: any, fileData: any) {
  try {
    const { contentType, base64Content } = extractBase64Content(base64Data);

    const fileBuffer = Buffer.from(base64Content, 'base64');

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': contentType,
      },
      body: fileBuffer,
    };
    const response = await fetch(presignedUrl, requestOptions);
    if (response.ok) {
      console.log('File uploaded successfully');
    } else {
      console.error('File upload failed:', response.statusText);
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  }
}

export function base64ToBlob(base64: string, contentType: string): Blob {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}

export const ConvertImgUrl = (transaction: ITransaction) => {
  return `data:image/jpeg;base64,${transaction.image} `;
};

export function timeAgo(date: string | Date): string {
  const now = new Date();
  const then = new Date(date);
  const seconds = Math.floor((now.getTime() - then.getTime()) / 1000);

  const intervals: { [key: string]: number } = {
    жил: 31536000,
    сар: 2592000,
    'долоо хоног': 604800,
    өдөр: 86400,
    цаг: 3600,
    минут: 60,
  };

  const mongolianPlural = (value: number, unit: string) => {
    return `${value} ${unit}${value !== 1 ? '' : ''} өмнө`; // Mongolian language does not typically use plurals in the same way as English
  };

  for (const [unit, value] of Object.entries(intervals)) {
    const interval = Math.floor(seconds / value);
    if (interval >= 1) {
      return mongolianPlural(interval, unit);
    }
  }

  return 'сая';
}

export const downloadFile = async (path: string) => {
  try {
    const response = await fetch(path, { mode: 'cors' });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', path.split('/').pop() || 'file'); // Extract filename from path
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url); // Clean up
    alert('Download started');
  } catch (error) {
    console.error('Error downloading file:', error);
    alert('Failed to download file');
  }
};
