import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

type Props = {
  onClick: () => void;
  text: string;
  isSolid?: boolean;
  isBordered?: boolean;
  isShadow?: boolean;
  disabled?: boolean;
  isDashed?: boolean;
  loading?: boolean;
  suffix?: React.ReactNode;
};

const Button = ({
  onClick,
  text,
  isSolid = false,
  isShadow = false,
  isDashed = false,
  disabled = false,
  isBordered = false,
  loading = false,
  suffix,
}: Props) => {
  const getButtonClasses = () => {
    let classes =
      'flex cursor-pointer text-sm text-center place-items-center w-full place-content-center py-2 rounded-lg px-3 ';

    if (isDashed) classes += 'border-dashed ';
    if (isShadow) classes += 'drop-shadow-xl ';

    if (disabled || loading) {
      classes += 'border-[#164789] bg-gray-200 text-gray-400 cursor-not-allowed ';
    } else {
      classes += isSolid
        ? `bg-white ${
            isBordered ? 'hover:bg-gray-100 border border-[#164789] text-[#164789]' : ''
          } `
        : 'bg-[#164789] text-white ';
    }

    return classes;
  };

  return (
    <button
      className={getButtonClasses()}
      onClick={disabled || loading ? undefined : onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <AiOutlineLoading3Quarters className="animate-spin h-5 w-5 mr-2" />
      ) : (
        suffix && <div className="mr-2">{suffix}</div>
      )}
      {text}
    </button>
  );
};

export default Button;
