import React from 'react';
import { FileContent } from 'use-file-picker/dist/interfaces';
import { FaRegFileLines } from 'react-icons/fa6';
import { FaRegFileImage } from 'react-icons/fa6';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useFilePicker } from 'use-file-picker';
import { IoMdCloudUpload } from 'react-icons/io';

type Props = {
  files: FileContent<string>[];
  onChange: (files: FileContent<string>[]) => void;
  buttonText?: string;
};

const AttachFile = ({ files, onChange, buttonText }: Props) => {
  const { openFilePicker } = useFilePicker({
    readAs: 'DataURL',
    accept: ['.jpg', '.png', '.pdf', '.docx'],
    multiple: true,
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      onChange([...files, ...filesContent]);
    },
  });

  const onRemove = (fileToRemove: any) => {
    onChange(files.filter((file) => file !== fileToRemove));
  };

  return (
    <>
      {files?.map((file, index) => (
        <div
          key={index}
          className="flex p-2 bg-white border place-items-center  border-gray-300 justify-between rounded-lg mb-2"
        >
          <div className="flex place-items-center gap-2">
            <div className="p-2 bg-gray-100 rounded-full">
              {file.name.endsWith('.docx') ? (
                <FaRegFileLines className="text-gray-500" />
              ) : (
                <FaRegFileImage className="text-gray-500" />
              )}
            </div>

            <p className="text-sm text-gray-800 ">{file.name}</p>
          </div>

          <AiFillCloseCircle
            className=" cursor-pointer text-lg text-red-500"
            onClick={() => onRemove(file)}
          />
        </div>
      ))}

      <div
        onClick={() => openFilePicker()}
        className=" h-[6rem]	border-dashed flex w-full items-center	 place-content-center rounded-lg border border-gray-400  hover:border-gray-500 bg-gray-50 hover:bg-gray-100 hover:bg- text-[#164789]"
      >
        <div className="grid place-items-center mx-2">
          <IoMdCloudUpload className="text-gray-400  h-10  w-10" />
          <p className="text-sm text-gray-500 font-medium	text-center	  ">
            {buttonText ? buttonText : 'Файл нэмэх'}
          </p>
        </div>
      </div>
    </>
  );
};

export default AttachFile;
