import { PAGE_TECHNICAL_CONDITIONS } from 'constants/pages';
import { useCallStore } from 'context';
import Header from 'layout/Header';
import { useNavigate } from 'react-router-dom';
import HomeContent from 'templates/HomeContent';

const Index = () => {
  let history = useNavigate();
  const { setStep } = useCallStore();

  const goFlow = () => {
    setStep(1);
    history(PAGE_TECHNICAL_CONDITIONS);
  };

  return (
    <>
      <Header />
      <HomeContent goFlow={goFlow} buttonText={'Ажил захиалах'} />
    </>
  );
};

export default Index;
