import RadioCard from 'components/Card/RadioCard';
import { PAGE_ORDER } from 'constants/pages';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import FormInputTemplate from 'templates/FormInputTemplate';
import FormTemplate from 'templates/FormTemplate';
import { FaPlugCircleBolt } from 'react-icons/fa6';
import { LuCheckCircle } from 'react-icons/lu';
import { RiUserSettingsLine } from 'react-icons/ri';
import { useState } from 'react';

const OrderConfig = () => {
  let history = useNavigate();
  const { code } = useParams<{ code: string }>();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const { handleSubmit, watch, setValue } = useForm<FieldValues>({
    mode: 'all',
    defaultValues: {
      step: 1,
      files: [],
    },
  });

  const { step } = watch();

  const goBackStep = () => {
    if (step === 2) {
      setValue('step', 1);
    } else if (step === 3) {
      setValue('step', 1);
    } else if (step === 1) {
      history(PAGE_ORDER + `/${code}`);
    }
  };

  const onSubmit = (data: FieldValues) => {
    if (data.step === 1) {
      setValue('step', 2);
    } else if (data.step === 2) {
      history(PAGE_ORDER + `/${code}`);
    }
  };

  const works = [
    {
      id: 0,
      name: 'Цахилгааны угсралт',
      logo: <FaPlugCircleBolt className="h-7 w-7" />,
    },
    {
      id: 1,
      name: 'Техникийн хяналт',
      logo: <RiUserSettingsLine className="h-7 w-7" />,
    },
  ];

  const onSelect = (item: any) => {
    const isSelected = selectedItems.some((selectedItem) => selectedItem.id === item.id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };
  return (
    <FormTemplate
      totalSteps={2}
      onBack={goBackStep}
      handleSubmit={handleSubmit}
      activeStep={step}
      onSubmit={onSubmit}
    >
      <FormInputTemplate isHide={step === 1} headerText="Ажилийн төрөл сонгох">
        <div className="grid grid-cols-1  sm:grid-cols-6 gap-2">
          {works.map((item) => (
            <div className="col-span-2	 h-full">
              <RadioCard item={item} selectedItems={selectedItems} onSelect={onSelect} />
            </div>
          ))}
        </div>
      </FormInputTemplate>
      <FormInputTemplate isHide={step === 2}>
        <div className=" flex w-full min-h-40 mt-24 items-center text-center place-content-center">
          <div className="grid place-items-center ">
            <LuCheckCircle className=" animate-pulse text-green-500 h-20 w-20" />
            <p className="font-medium mt-10">Таны ажлын захиалга амжиллттай нийтлэгдлээ</p>
            <p className="mt-6">Таны ажлын захиалга бүртгэлтэй компаниудад мэдэгдэл очих болно.</p>
          </div>
        </div>
      </FormInputTemplate>
    </FormTemplate>
  );
};

export default OrderConfig;
