import { PAGE_AUTH, PAGE_INDEX } from 'constants/pages';
import { GrLinkNext } from 'react-icons/gr';
import { MdOutlineArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  let history = useNavigate();

  return (
    <div className="grid  grid-cols-1 md:grid-cols-2 h-screen">
      <div className="">
        <div className="w-full p-8 ">
          <MdOutlineArrowBack className="w-7 h-7" onClick={() => history(PAGE_INDEX)} />
        </div>
        <div className="w-full text-center mb-4">
          <span className="text-xl font-semibold">Бүртгүүлэх / Нэвтрэх</span>
        </div>
        <div className="flex flex-row">
          <div className=" basis-0 sm:basis-1/4 md:basis-1/6 lg:basis-1/6 xl:basis-1/8 2xl:basis-3/6"></div>
          <div className="w-full px-4  sm:px-0 sm:basis-1/2 md:basis-8/12 lg:basis-8/12 xl:basis-8/12 2xl:basis-8/12">
            <div
              onClick={() => history(PAGE_AUTH)}
              className=" cursor-pointer   flex  w-full justify-between  p-4 border border-gray-200 rounded-lg mb-4"
            >
              <div className="grid content-center  ">
                <span className="text-base font-semibold text-gray-800 font-sans">
                  Ажил захиалагч
                </span>
                <span className="text-sm text-gray-700  font-sans">
                  Book salons and spas near you
                </span>
              </div>
              <div className="content-center">
                <GrLinkNext />
              </div>
            </div>
          </div>
          <div className="basis-0 sm:basis-1/4  md:basis-1/6 lg:basis-1/6 xl:basis-1/8 2xl:basis-3/6"></div>
        </div>
        <div className="flex flex-row">
          <div className=" basis-0 sm:basis-1/4 md:basis-1/6 lg:basis-1/6 xl:basis-1/8 2xl:basis-3/6"></div>
          <div className="w-full  px-4  sm:px-0  sm:basis-1/2 md:basis-8/12 lg:basis-8/12 xl:basis-8/12 2xl:basis-8/12">
            <div className="  cursor-pointer flex  w-full justify-between  p-4 border border-gray-200 rounded-lg mb-4">
              <div className="grid content-center  ">
                <span className="text-base font-semibold text-gray-800 font-sans">
                  Ажил гүйцэтгэгч
                </span>
                <span className="text-sm text-gray-700  font-sans">
                  Manage and grow your business
                </span>
              </div>
              <div className="content-center">
                <GrLinkNext />
              </div>
            </div>
          </div>
          <div className="basis-0 sm:basis-1/4  md:basis-1/6 lg:basis-1/6 xl:basis-1/8 2xl:basis-3/6"></div>
        </div>
      </div>

      <div className=" hidden   md:flex ">
        <img src="/images/login.jpg" alt="login" className="bg-contain bg-center h-dvh w-dvw" />
      </div>
    </div>
  );
};

export default Index;
