import React from 'react';
import Header from '../layout/Header';
import Step from '../components/Step';

type Props = {
  children: React.ReactNode;
  footer?: React.ReactNode;
  totalSteps?: number;
  activeStep?: number;
};

const MainTemplate = ({ children, footer, totalSteps, activeStep }: Props) => {
  return (
    <>
      <Header />
      <section className=" text-gray-600 body-font py-24 px-4 grid items-center justify-center  h-screen grid-cols-12 lg:grid-cols-9 xl:grid-cols-11 2xl:grid-cols-11   ">
        <div
          className="col-start-1   col-span-12
        sm:col-start-4 sm:col-span-6
         md:col-start-3   md:col-span-8 
         lg:col-start-4   lg:col-span-3
         xl:col-start-5   xl:col-span-3
         2xl:col-start-5  2xl:col-span-3
             "
        >
          {totalSteps && activeStep && <Step totalSteps={totalSteps} activeStep={activeStep} />}
          {children}
        </div>
        <div
          className="col-start-1   col-span-12
        sm:col-start-4 sm:col-span-6
         md:col-start-3   md:col-span-8 
         lg:col-start-4   lg:col-span-3
         xl:col-start-5   xl:col-span-3
         2xl:col-start-5  2xl:col-span-3"
        >
          {footer}
        </div>
      </section>
    </>
  );
};

export default MainTemplate;
