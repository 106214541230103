import Button from '../components/Button';
import Footer from '../layout/Footer';

type Props = {
  goFlow: () => void;
  goSecondFlow?: () => void;
  buttonText: string;
  secondButtonText?: string;
};

const HomeContent = ({ goFlow, buttonText, secondButtonText, goSecondFlow }: Props) => {
  return (
    <section className="text-gray-600 body-font h-screen bg-white">
      <div className="max-w-7xl mx-auto flex px-5 py-64  md:py-0  h-full pb-1 md:flex-row flex-col items-center ">
        <div className="lg:flex-grow md:w-1/2 md:ml-24 pt-6 flex flex-col md:items-start md:text-left mb-20 items-center text-center">
          <h1 className="mb-5 sm:text-6xl text-5xl items-center Avenir xl:w-2/2 text-[#164789]">
            Цахилгаан угсралт
          </h1>
          <p className="mb-4 xl:w-3/4 text-gray-600 text-lg">
            Цахилгаан угсралтын ажил захиалагч болон гүйцэтгэгч компаниудын нэгдсэн порталд тавтай
            морилно уу
          </p>
          {secondButtonText && (
            <p className="mb-4 font-semibold w-full text-gray-600 text-lg  text-center">
              Хэрэглэгчийн төрлөө сонгоно уу.
            </p>
          )}
          <div className=" grid grid-cols-1 md:grid-cols-2  justify-start gap-2 w-full  ">
            <Button onClick={goFlow} text={buttonText} isShadow />
            {secondButtonText && goSecondFlow && (
              <Button onClick={goSecondFlow} text={secondButtonText} isShadow />
            )}
          </div>
        </div>
        <div className=" hidden lg:flex xl:mr-44 sm:mr-0 sm:mb-28 mb-0 lg:mb-0 mr-48 md:pl-10 ">
          <img className="w-80 md:ml-1 ml-24" alt="iPhone-12" src="/images/connection.jpg"></img>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default HomeContent;
