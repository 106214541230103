type Props = {
  item: any;
  selectedItems: any[];
  onSelect: (item: any) => void;
};

const RadioCard = ({ item, onSelect, selectedItems }: Props) => {
  const isSelected = selectedItems.some((selectedItem) => selectedItem.id === item.id);

  return (
    <div
      className={`p-4 border-2 cursor-pointer  ${
        isSelected ? 'border-gray-600' : 'border-gray-200'
      }   rounded-lg`}
      onClick={() => onSelect(item)}
    >
      <div>{item.logo} </div>
      <span className="text-sm font-semibold">{item.name}</span>
    </div>
  );
};

export default RadioCard;
