import { gql } from '@apollo/client';

export const GET_VERIFY = gql`
  mutation getVerify($type: VerificationType!, $phone: String!) {
    getVerify(type: $type, phone: $phone) {
      token
    }
  }
`;
export const VERIFY = gql`
  mutation verify($token: String!, $code: String!) {
    verify(token: $token, code: $code) {
      token
    }
  }
`;
