import { useMutation } from '@apollo/client';
import ProgressIndicator from 'components/ ProgressIndicator';
import Button from 'components/Button';
import CountdownTimer from 'components/CountdownTimer';
import PartnerInfo from 'components/PartnerInfo';
import { PAGE_ORDER } from 'constants/pages';
import { TECHNICAL_CONDITIONS } from 'constants/type';
import { CHECK_ADMIN_DOCUMENT } from 'graphql/mutation';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FiEye } from 'react-icons/fi';
import { IoMdAttach } from 'react-icons/io';
import { MdOutlineFileDownload } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { orderStatusColor } from 'tools';
import { IDocument, IOrder, ITask } from 'types';
import { downloadFile } from 'utils';

type OrderCardProps = {
  order: IOrder;
};

const OrderCard = ({ order }: OrderCardProps) => {
  let history = useNavigate();
  const { t } = useTranslation('language');
  const [task, setTask] = useState<ITask>();
  const [adminDocument, setIsAdminDocumentPresent] = useState<IDocument[]>();
  const [checkAdminDocument, { loading }] = useMutation(CHECK_ADMIN_DOCUMENT, {
    onCompleted: (data) => {
      setIsAdminDocumentPresent(data.adminDocument);
    },
  });
  useEffect(() => {
    if (!isEmpty(order.tasks)) {
      const targetTask = order.tasks.find((task: ITask) => task.type === TECHNICAL_CONDITIONS);
      setTask(targetTask);

      if (order) {
        checkAdminDocument({ variables: { id: order.id } });
      }
    }
  }, [order, checkAdminDocument]);

  const onRowClicked = (value: any) => {
    if (!value) return;
    history(PAGE_ORDER + `/${value.code}`);
  };

  if (loading)
    return (
      <div className="flex w-full justify-center p-10 ">
        <AiOutlineLoading3Quarters className="animate-spin  h-12 w-12 text-gray-500  " />
      </div>
    );

  const renderDocument = (document: IDocument, index: number, name: string) => {
    return (
      <div key={index} className="flex  ">
        <div className="flex gap-2">
          <FiEye
            className="cursor-pointer text-lg text-gray-500"
            onClick={() => window.open(document.path)}
          />
          <MdOutlineFileDownload
            className="cursor-pointer text-lg text-gray-500"
            onClick={() => downloadFile(document.path)}
          />
        </div>
      </div>
    );
  };

  const renderAdminDocument = (document: IDocument, index: number, name: string) => {
    return (
      <div
        key={index}
        className="flex p-2 border border-gray-300 rounded-lg place-items-center justify-between mb-2"
      >
        <div className="flex place-items-center gap-2">
          <IoMdAttach className="text-gray-500" />
        </div>
        <p className="text-sm mx-2 text-gray-700 font-normal w-full truncate whitespace-nowrap overflow-hidden text-ellipsis">
          {`${name}_${moment(order.date).format('YYYY_MM_DD')}` ?? document.name}
        </p>
        <div className="flex gap-2">
          <FiEye
            className="cursor-pointer text-lg text-gray-500"
            onClick={() => window.open(document.path)}
          />
          <MdOutlineFileDownload
            className="cursor-pointer text-lg text-gray-500"
            onClick={() => downloadFile(document.path)}
          />
        </div>
      </div>
    );
  };

  const convertStep = (order: IOrder) => {
    let step = 0;

    let offers = order.tasks.flatMap((task) => task.offers);

    if (isEmpty(adminDocument)) {
      step = 1;
    } else if (order.state === 'PENDING' && !isEmpty(adminDocument)) {
      step = 2;
    } else if (order.state === 'ACCEPTED') {
      step = 3;
    } else if (
      order.state === 'COMPLETED' &&
      !isEmpty(offers.filter((offer) => offer.state === 'COMPLETED'))
    ) {
      step = 4;
    }
    return step;
  };

  return (
    <div className="w-full mb-8 ">
      <div className="p-4 pt-2 border  rounded-lg mt-3 shadow-lg">
        <div className="w-full">
          <div className="pt-2 pb-9">
            <ProgressIndicator currentStep={convertStep(order)} />
          </div>
          <div className="flex place-content-between text-xs mt-2 text-gray-500">
            <span>Oгноо:</span>
            <span className="font-semibold">{moment(order.date).format('YYYY-MM-DD')}</span>
          </div>
          {order.state !== 'COMPLETED' && (
            <div className="flex place-content-between text-xs mt-2 text-gray-500">
              <span>Үнийн саналын тоо:</span>
              <span className="text-xs font-semibold">{task?.offers.length}</span>
            </div>
          )}

          <div className="flex place-content-between text-xs mt-2 text-gray-500">
            <span>Төлөв:</span>
            <span
              className={` rounded-lg text-xs font-normal  py-1 px-2 cursor-pointer ${orderStatusColor(
                order.state,
              )} `}
            >
              {t(`${order.state}`)}
            </span>
          </div>

          <div className="flex place-content-between text-xs mt-2 text-gray-500">
            <span className="text-xs text-gray-500">Техникийн нөхцөл:</span>
            {isEmpty(task) ? (
              <>
                <span className="text-xs text-gray-800">Хоосон</span>
              </>
            ) : (
              <>
                {task?.documents
                  ?.filter((d) => d.input === 'user')
                  ?.map((doc: IDocument, index) => renderDocument(doc, index, 'Техникийн нөхцөл'))}
              </>
            )}
          </div>
          {(order.state === 'COMPLETED' || order.state === 'ACCEPTED') && (
            <>
              {!isEmpty(adminDocument) && (
                <div className="flex place-content-between text-xs mt-2 text-gray-500">
                  <span className="text-xs text-gray-500">Ажлын эзлэхүүн:</span>

                  {adminDocument &&
                    adminDocument.map((document) => renderDocument(document, 2, 'ажлын эзлэхүүн'))}
                </div>
              )}

              {order.tasks
                ?.filter((t) => t.state === 'COMPLETED' || t.state === 'ACCEPTED')
                ?.map((task) => {
                  let offer = task.offers;

                  console.log('offer', offer);
                  return (
                    <>
                      {offer.map((o) => {
                        return <PartnerInfo offer={o} />;
                      })}
                    </>
                  );
                })}
            </>
          )}

          {order.state === 'PENDING' && (
            <>
              {!isEmpty(adminDocument) && (
                <div
                  className="p-4  text-xs mt-2 text-blue-800 border place-items-start  border-blue-300 rounded-lg bg-blue-50  "
                  role="alert"
                >
                  <div className="flex items-center mb-4">
                    <svg
                      className="flex-shrink-0 inline w-5 h-5 me-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="text-blue-800 font-bold text-bold">
                      Таны ажлын эзлэхүүн бэлэн боллоо{' '}
                    </span>
                  </div>
                  <div className=" gap-1">
                    {adminDocument &&
                      adminDocument.map((document) =>
                        renderAdminDocument(document, 2, 'ажлын_эзлэхүүн'),
                      )}
                    <span className="text-blue-800">
                      Бүртгэлтэй компаниуд уг ажлын эзэлхүүнтэй танилцаж ажлын 5 хоногт үнийн санал
                      өгөх болно.
                    </span>
                  </div>
                </div>
              )}
            </>
          )}

          {isEmpty(adminDocument) && (
            <div
              className="p-4 flex text-xs mt-4 text-blue-800 border place-items-start  border-blue-300 rounded-lg bg-blue-50  "
              role="alert"
            >
              <svg
                className="flex-shrink-0 inline w-5 h-5 me-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <div className="grid gap-1">
                <span className="text-blue-800">
                  Уг техникийн нөхцөлийн дагуу хийгдэх ажлын эзлэхүүн 72 цагийн дараа бэлэн болох ба
                  таны бүртгэлтэй утас руу мэдэгдэх болно.
                </span>
                <div className="w-full text-center">
                  <span className=" text-lg text-blue-800 font-semibold">
                    <CountdownTimer pendingAt={order.pendingAt} />
                  </span>
                </div>
              </div>
            </div>
          )}
          {order.state === 'PENDING' && (
            <>
              {!isEmpty(order.tasks) && (
                <>
                  {!isEmpty(order.tasks?.flatMap((task) => task.offers)) && (
                    <>
                      <div className="w-full my-4 ">
                        <Button
                          text={`📩 Үнийн саналтай танилцах (${task?.offers.length})`}
                          onClick={() => onRowClicked(order)}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {order?.tasks?.map((task) => {
          if (!task.detailedPicture) return null;
          return (
            <div className="w-full mt-4">
              {isEmpty(task?.workTypes?.filter((w) => w === 'workingDraw')) && (
                <div className="w-full ">
                  <div
                    className={`flex items-center p-3 text-xs  bg-yellow-50 text-yellow-800 rounded-lg `}
                    role="alert"
                  >
                    <svg
                      className="flex-shrink-0 inline w-5 h-5 me-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <div className="grid gap-1">
                      <span className="text-yellow-800 font-bold text-bold">
                        Батлагдсан ажлын зураг шаардлагатай
                      </span>
                      <span className="text-yellow-800">
                        Уг техникийн нөхцлийн дагуу ажлыг гүйцэтгэхэд батлагдсан ажлын зураг төсөл
                        шаардлагатай. Эхэлж зураг төслөө хийлгэх бол
                      </span>
                    </div>
                  </div>
                  <div className="w-full mt-2 ">
                    <Button
                      text="ЭНД ДАРНА УУ"
                      onClick={() => history(`/order-process/${order.code}`)}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrderCard;
