import { gql } from '@apollo/client';

export const ACCEPTED_OFFER = gql`
  mutation acceptedOffer($id: ID!) {
    acceptedOffer(id: $id) {
      id
      createdAt
      price
      state
      partnerId
      types
    }
  }
`;

export const PAY_CHARGE = gql`
  mutation payCharge($id: ID!) {
    payCharge(id: $id) {
      id
      token
      invoice
      image
      description
      entry
      amount
      state
      purpose
      object
      links {
        name
        logo
        description
        link
      }
    }
  }
`;
