import * as yup from 'yup';
import { PATTERN_PHONE } from 'constants/pattern';

export const loginSchema = yup.object().shape({
  phone: yup
    .string()
    .required('Та утасны дугаар оруулна уу')
    .matches(PATTERN_PHONE, 'Утасны дугаар алдаатай байна'),

  password: yup
    .string()
    .min(6, 'Нууц үг 6 оронтой байх шаардлагатай')
    .required('Нууц үгээ оруулна уу'),
});
