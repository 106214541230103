import { gql } from '@apollo/client';

export const GET_PARTNER_LICENSE = gql`
  query getPartnerLicense($id: ID!, $type: String) {
    getPartnerLicense(id: $id, type: $type) {
      id
      type
      specialPermit
      name
      path
      experience
    }
  }
`;

export const GET_OFFER_PARTNER = gql`
  query getOfferPartner($id: ID!) {
    getOfferPartner(id: $id) {
      id
      phone
      name
    }
  }
`;
