import { useQuery } from '@apollo/client';
import { GET_OFFER_PARTNER } from 'graphql/query';
import { IOffer } from 'types/offer';

type Props = {
  offer: IOffer;
};

const PartnerInfo = ({ offer }: Props) => {
  const { data: partner, loading } = useQuery(GET_OFFER_PARTNER, {
    skip: !offer,
    variables: { id: offer?.id },
  });

  return (
    <div>
      <div className="flex place-content-between text-xs mt-2 text-gray-500">
        <span>Байгууллагын нэр:</span>
        <span className="font-semibold text-end">{partner?.getOfferPartner?.name}</span>
      </div>

      <div className="flex place-content-between text-xs mt-2 text-gray-500">
        <span>Байгууллагын утас:</span>
        <span className="font-semibold text-end">{partner?.getOfferPartner?.phone}</span>
      </div>
    </div>
  );
};

export default PartnerInfo;
