import { IoIosNotificationsOutline } from 'react-icons/io';
import { CiCircleCheck } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { PAGE_ORDER } from 'constants/pages';
import { GET_ORDERS, GET_TASKS } from 'graphql/query';
import { useMutation, useQuery } from '@apollo/client';
import { IOrder, ITask } from 'types';
import { useTranslation } from 'react-i18next';
import { timeAgo } from 'utils';
import { READ_TASK } from 'graphql/mutation/task';

const Index = () => {
  let history = useNavigate();
  const { t } = useTranslation('language');
  const { data, refetch } = useQuery(GET_TASKS);
  const { data: getOrders } = useQuery(GET_ORDERS);
  const [readTask] = useMutation(READ_TASK, {
    onCompleted(data) {
      refetch();
    },
  });
  const tasks = (data?.getTasks ?? []).filter((task: ITask) => task.state === 'COMPLETED');

  let count =
    data &&
    data?.getTasks?.filter((task: ITask) => !task.isRead && task.state === 'COMPLETED').length;

  const goTask = async (task: ITask) => {
    const order = getOrders.getOrders.find((order: IOrder) =>
      order.tasks.some((t) => t.id === task.id),
    );
    if (!order) return;
    await readTask({ variables: { id: task.id } });
    history(PAGE_ORDER + `/${order.code}`);
  };

  return (
    <div className="dropdown ">
      <div className="p-1 relative  bg-gray-200 rounded-lg  mr-2  ">
        <IoIosNotificationsOutline
          tabIndex={0}
          className=" h-6 w-6  text-[#164789]  rounded-lg focus:border-transparent focus:outline-none focus:ring-1 focus:ring-gray-200"
        />
        <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 ">
          {count ?? 0}
        </div>
      </div>
      <div className="dropdown-menu bg-white w-72 ">
        {tasks.map((task: ITask, index: number) => (
          <p
            key={index}
            className={`dropdown-item ${index !== tasks.length - 1 ? 'mb-2' : ''}  text-sm ${
              task.isRead ? 'bg-white' : 'bg-gray-100'
            } `}
            onClick={() => {
              goTask(task);
            }}
          >
            <div className="flex place-self-center gap-2  ">
              <div className="flex">
                <CiCircleCheck className="text-green-500 text-3xl" />
              </div>
              <div>
                <p className="text-sm mb-1 font-semibold text-gray-800 "> {t(`${task.type}`)}</p>
                <p className="text-xs mb-1 text-gray-500 block-ellipsis-2 ">
                  Таны ажлын тоо хэмжээ бэлэн боллоо
                </p>
                <p className="text-xs text-gray-400">
                  {task.completedAt ? timeAgo(task.completedAt) : 'Not completed yet'}
                </p>
              </div>
            </div>
          </p>
        ))}
      </div>
    </div>
  );
};

export default Index;
