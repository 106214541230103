import { useQuery } from '@apollo/client';
import { GET_OFFER_PARTNER, GET_PARTNER_LICENSE } from 'graphql/query';
import { IOffer } from 'types';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { certificateTypesName, electricalInstallationPermit, workingDrawPermit } from 'mocks';
import Button from 'components/Button';
import { useRef } from 'react';
import { formatWithCommas } from 'utils';

type Props = {
  offer: IOffer;
  onAccept: (offer: IOffer) => void;
};

const Index = ({ offer, onAccept }: Props) => {
  const modalRef = useRef<any>(null);
  const { data, loading: licensing } = useQuery(GET_PARTNER_LICENSE, {
    skip: !offer,
    variables: {
      id: offer.partnerId,
      type: offer.types?.find(
        (e: string) =>
          e === 'electricalInstallation' || e === 'technicalControl' || e === 'workingDraw',
      ),
    },
  });

  const { data: partner, loading } = useQuery(GET_OFFER_PARTNER, {
    skip: !offer,
    variables: { id: offer?.id },
  });

  const permits = [...certificateTypesName, ...electricalInstallationPermit, ...workingDrawPermit];

  if (licensing)
    return (
      <div className="grid place-items-center  ">
        <AiOutlineLoading3Quarters className="animate-spin  h-12 w-12 text-gray-500  " />
      </div>
    );

  const experience = data?.getPartnerLicense?.experience ?? 'Хоосон';
  const specialPermit = data?.getPartnerLicense?.specialPermit ?? 'Хоосон';
  const permitType = permits.find((permit) => permit.value === specialPermit)?.name ?? 'Хоосон';

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.checked = false;
    }
  };

  const goCharge = () => {
    closeModal();
    onAccept(offer);
  };

  return (
    <>
      <div className="flex place-content-between text-xs mt-2 text-gray-500">
        <span>Компанийн туршлага:</span>
        <span className="font-semibold">{experience}</span>
      </div>
      <div className="flex place-content-between text-xs mt-2 text-gray-500">
        <span>Тусгай зөвшөөрлийн төрөл:</span>
        <span className="font-semibold text-end">{permitType}</span>
      </div>

      {offer.state === 'COMPLETED' && (
        <>
          <div className="flex place-content-between text-xs mt-2 text-gray-500">
            <span>Байгууллагын нэр:</span>
            <span className="font-semibold text-end">{partner?.getOfferPartner?.name}</span>
          </div>

          <div className="flex place-content-between text-xs mt-2 text-gray-500">
            <span>Байгууллагын утас:</span>
            <span className="font-semibold text-end">{partner?.getOfferPartner?.phone}</span>
          </div>
        </>
      )}

      {offer.state === 'ACCEPTED' && (
        <div
          className="p-4 flex text-xs mt-2 text-blue-800 border place-items-start  border-blue-300 rounded-lg bg-blue-50  "
          role="alert"
        >
          <svg
            className="flex-shrink-0 inline w-5 h-5 me-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <div className="grid gap-1">
            <span className="text-blue-800">
              Та энэхүү үнийн саналыг хүлээн зөвшөөрсөн тул гйцэтгэгч компаниас хариу өгтөл түр
              хүлээнэ үү
            </span>
          </div>
        </div>
      )}

      <div className="w-ful mt-2">
        {offer.state === 'COMPLETED' && (
          <div className="p-2  cursor-pointer bg-green-200 text-center rounded-lg">
            <span className="  text-green-800 text-sm ">Сонгогдсон</span>
          </div>
        )}
        {offer.state === 'ACCEPTED' && (
          <div className="p-2  cursor-pointer bg-yellow-100 text-center rounded-lg">
            <span className="  text-yellow-500 text-sm ">Хүлээгдэж байгаа</span>
          </div>
        )}
        {offer.state === 'PENDING' && (
          <label htmlFor="modal-4">
            <div className="p-2  cursor-pointer bg-[#164789] text-center rounded-lg">
              <span className="  text-white text-sm ">Сонгох</span>
            </div>
          </label>
        )}
      </div>

      <input ref={modalRef} className="modal-state" id="modal-4" type="checkbox" />
      <div className="modal">
        <label className="modal-overlay" htmlFor="modal-4"></label>
        <div className="modal-content flex flex-col w-11/12	 sm:w-5/12 p-8 pt-2 ">
          <label
            htmlFor="modal-4"
            className="btn btn-sm btn-circle btn-ghost absolute right-1 top-1"
          >
            ✕
          </label>
          <div className="p-4 mb-4 mt-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50  ">
            <div className="flex items-start">
              <div className="h-full">
                <svg
                  className="flex-shrink-0 w-5 h-5 me-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
              </div>

              <span className="sr-only">Info</span>
              <span className="text-sm font-medium w-full text-center  items-center ">
                <span> Үйлчилгээний шимтгэл 2%-иар тооцогдоно. </span>
                <div className="w-full  items-center text-center my-2">
                  <span className=" font-semibold ">
                    Шимтгэл ₮ {formatWithCommas(offer?.chargeAmount)}
                  </span>
                </div>
                <span>Энэхүү шимтгэл нь нийт үнийн саналдаа багтсан болно</span>
              </span>
            </div>
          </div>
          <div className="  w-full">
            <Button text="Үргэлжлүүлэх" onClick={goCharge} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
