import Avatar from 'components/Avatar';
import Notification from 'components/Notification';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import { PAGE_INDEX, PAGE_ORDER } from 'constants/pages';
import Button from 'components/Button';
import { useRef } from 'react';
import { useQuery } from '@apollo/client';
import { GET_USER } from 'graphql/query';
import { getAccessToken } from 'providers';

const Header = () => {
  const token = getAccessToken();
  const modalRef = useRef<HTMLInputElement>(null);
  let history = useNavigate();
  const { data } = useQuery(GET_USER, {
    skip: !token,
  });

  const goOrder = () => {
    history(PAGE_ORDER);
  };

  const onModal = (status: boolean) => {
    if (modalRef.current) {
      modalRef.current.checked = status;
    }
  };

  return (
    <div className="fixed top-0 w-full z-30 clearNav  transition duration-300 ease-in-out">
      <div className="flex flex-col max-w-6xl bg-white mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
        <div className="flex flex-row items-center justify-between p-1 sm:p-4 ">
          <a
            href="/"
            className=" flex text-lg font-semibold rounded-lg tracking-widest focus:outline-none focus:shadow-outline"
          >
            <img src="/images/logo.png" alt="logo" className=" mr-2 h-10" />
            <h1 className="text-xl place-content-center Avenir tracking-tighter text-[#164789] md:text-4x1 lg:text-3xl">
              EPOWER.mn
            </h1>
          </a>
          <div className="flex place-items-center">
            {token && (
              <div className="p-1 relative   rounded-lg  md:hidden ">
                <div className="flex">
                  <Notification />
                </div>
              </div>
            )}
            <div className="p-1 relative   rounded-lg  md:hidden ">
              <Avatar />
            </div>
            <div className="flex  justify-center leading-none px-3 py-1 md:hidden">
              <div className="dropdown ">
                <RxHamburgerMenu
                  tabIndex={0}
                  className=" h-6 w-6 text-gray-800  rounded-lg focus:border-transparent focus:outline-none focus:ring-1 focus:ring-white"
                />
                <div className="dropdown-menu bg-white w-40">
                  <p
                    className="dropdown-item text-sm text-gray-800 hover:bg-gray-300 "
                    onClick={() => history(PAGE_INDEX)}
                  >
                    Нүүр хуудас
                  </p>
                  <p className="dropdown-item text-sm text-gray-800 hover:bg-gray-300">
                    {' '}
                    Бидний тухай
                  </p>
                  {token && (
                    <p
                      onClick={goOrder}
                      className="dropdown-item text-sm  text-gray-800 hover:bg-gray-300"
                    >
                      Захиалга
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex flex-grow items-center hidden">
          <nav className="flex-col flex-grow ">
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              <li>
                <a
                  href="/"
                  className="font-medium text-sm text-gray-600 hover:text-[#164789] px-5 py-3 flex items-center transition duration-150 ease-in-out"
                >
                  Нүүр хуудас
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  className="font-medium text-sm text-gray-600 hover:text-[#164789] px-5 py-3 flex items-center transition duration-150 ease-in-out"
                >
                  Бидний тухай
                </a>
              </li>

              {token && (
                <li>
                  <p
                    onClick={goOrder}
                    className="font-medium text-sm text-gray-600 hover:text-[#164789] px-5 py-3 flex items-center transition duration-150 ease-in-out"
                  >
                    Захиалга
                  </p>
                </li>
              )}
              {token && (
                <li className="flex">
                  <Notification />
                </li>
              )}
              <li>
                <Avatar />
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {token && (
        <>
          <div className="block  w-full">
            <input ref={modalRef} className="modal-state" id="modal-1" type="checkbox" />
            <div className="modal h-screen w-full	">
              <label className="modal-overlay" htmlFor="modal-1"></label>
              <div className="modal-content flex flex-col  w-full p-8 pt-2 ">
                <div className="flex place-items-center place-content-between">
                  <p className="text-gray-800 font-semibold mt-2 ">Хэрэглэгчийн мэдээлэл</p>
                  <label
                    htmlFor="modal-1"
                    className="btn btn-sm btn-circle btn-ghost absolute right-1 top-1"
                  >
                    ✕
                  </label>
                </div>
                <div className="flex w-full">
                  <div className="grid w-full">
                    <div className="flex w-full justify-between mt-2">
                      <span>Овог:</span>
                      {data?.getUser.lastName ? (
                        <span>{data?.getUser.lastName}</span>
                      ) : (
                        <span className="text-gray-400 text-sm content-center">Хоосон</span>
                      )}
                    </div>
                    <div className="flex w-full justify-between mt-1">
                      <span>Нэр:</span>
                      {data?.getUser.firstName ? (
                        <span>{data?.getUser.firstName}</span>
                      ) : (
                        <span className="text-gray-400 text-sm content-center">Хоосон</span>
                      )}
                    </div>
                    <div className="flex w-full justify-between mt-1 mb-2">
                      <span>Утас:</span>
                      {data?.getUser.phone ? (
                        <span>{data?.getUser.phone}</span>
                      ) : (
                        <span className="text-gray-400 text-sm content-center">Хоосон</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="  w-full">
                  <Button text="Хаах" onClick={() => onModal(false)} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
