import Header from 'layout/Header';
import pic from './about.png';
import Footer from 'layout/Footer';

const About = () => {
  return (
    <>
      <Header />
      <section className="text-gray-600 body-font h-screen bg-white">
        <div className="max-w-2xl mx-auto grid px-5 py-0   md:py-0  h-full pb-1 md:flex-row flex-col items-center ">
          <div className=" place-content-center">
            <img src={pic} className="bg-auto bg-no-repeat bg-center" />
            <p className="indent-8 mt-4 ">
              Манай компани нь цахилгаан түгээх сүлжээнд холбогдох техникийн нөхцөл авсан
              хэрэглэгчдийг цахилгааны зураг төсөл зохиогч, цахилгааны угсралтын компаниуд болон
              ажил гүйцэтгэх бригадуудтай холбон зуучлах зорилгоор үйл ажиллагаагаа эхлүүлээд байна.
              Бид хэрэглэгчийн сэтгэл ханамжийг өндөр байлгах, хурдан шуурхай үйлчилгээ авах
              боломжийг бүрдүүлэхийг зорин ажиллаж байна.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
