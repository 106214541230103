import React from 'react';
import { Controller, Control, FieldError } from 'react-hook-form';
import AttachFile from 'components/AttachFile';
import { FileContent } from 'use-file-picker/dist/interfaces';
interface IndexProps {
  control: Control<any, any>;
  name: string;
  buttonText?: string;
}

const Index: React.FC<IndexProps> = ({ control, name, buttonText }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        const onChangeFile = (files: FileContent<string>[]) => {
          onChange(files);
        };

        return (
          <>
            <AttachFile files={value} onChange={onChangeFile} buttonText={buttonText} />
            <p className="mt-2 mb-2 text-red-500 text-xs">
              {errors && (errors[name] as FieldError)?.message}
            </p>
          </>
        );
      }}
    />
  );
};

export default Index;
