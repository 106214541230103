import Qpay from 'components/Payment/Qpay';
import { Control, Controller, FieldError } from 'react-hook-form';

interface IndexProps {
  control: Control<any, any>;
  name: string;
}

const Index: React.FC<IndexProps> = ({ control, name }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <>
          <p className="mt-2 mb-2 text-red-500 text-xs">
            <Qpay onSelect={onChange} paymentType={value} />
            <p className="text-red-500 text-xs text-center mt-4">
              {errors && (errors[name] as FieldError)?.message}{' '}
            </p>
          </p>
        </>
      )}
    />
  );
};

export default Index;
