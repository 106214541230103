import { gql } from '@apollo/client';
export const GET_TRANSACTIONS = gql`
  {
    getTransactions {
      id
      token
      invoice
      image
      description
      entry
      amount
      payedAt
      state
      purpose
      object
      links {
        name
        logo
        description
        link
      }
    }
  }
`;

export const GET_TRANSACTION = gql`
  query getTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      token
      invoice
      image
      description
      entry
      amount
      state
      purpose
      payedAt
      object
      links {
        name
        logo
        description
        link
      }
    }
  }
`;

export const GET_ORDER_TRANSACTION = gql`
  query getOrderTransaction($id: ID!) {
    getOrderTransaction(id: $id) {
      id
      token
      invoice
      image
      description
      entry
      amount
      state
      purpose
      payedAt
      object
      links {
        name
        logo
        description
        link
      }
    }
  }
`;
