export const PAGE_INDEX = '/';
export const PAGE_LOGIN = '/login';
export const PAGE_REGISTER = '/register';
export const PAGE_SIGN_IN = '/signin';
export const PAGE_FORGOT_PASSWORD = '/forgot-password';
export const PAGE_RESET_PASSWORD = '/reset-password';
export const PAGE_404 = '/404';
export const PAGE_TECHNICAL_CONDITIONS = '/technical-conditions';
export const PAGE_ORDER = '/order';
export const PAGE_ORDER_PROCESS = '/order-process';
export const PAGE_USER_FLOW = '/user-flow';
export const PAGE_AUTH = '/auth';
export const PAGE_ORDER_CONFIG = '/order-config';
export const PAGE_ABOUT = '/about';
export const PAGE_USER = '/user';
