import React from 'react';

const Footer = () => {
  return (
    <footer className=" bottom-0 w-full bg-white  p-4">
      <div className="max-w-6xl xl:max-w-6xl mx-auto divide-y divide-gray-200 px-4 sm:px-6 md:px-8">
        <div className="flex flex-col-reverse pt-5 pb-4 border-t lg:flex-row bg-top border-black justify-center">
          <p className="text-sm  text-center text-black transition-colors duration-300 hover:text-deep-purple-accent-400 font-normal tracking-tight">
            © 2024
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
