import { gql } from '@apollo/client';

export const READ_TASK = gql`
  mutation readTask($id: ID!) {
    readTask(id: $id)
  }
`;

export const ATTACH_WORK_DRAW = gql`
  mutation attachWorkDraw($id: ID!, $upload: String, $type: String) {
    attachWorkDraw(id: $id, upload: $upload, type: $type) {
      id
      type
      amount
      state
      uploads
      documents {
        id
        name
        path
      }
    }
  }
`;

export const ADD_TASK_TYPES = gql`
  mutation addTaskTypes($id: ID!, $types: [String!]) {
    addTaskTypes(id: $id, types: $types) {
      id
      type
      state
      isRead
      completedAt
      detailedPicture
      orderId
      documents {
        id
        name
        path
        input
      }
    }
  }
`;
