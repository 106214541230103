import Header from 'layout/Header';
import { useQuery } from '@apollo/client';
import { GET_ORDERS } from 'graphql/query';
import { IOrder } from 'types';
import Button from 'components/Button';
import { PAGE_TECHNICAL_CONDITIONS } from 'constants/pages';
import { useNavigate } from 'react-router-dom';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useCallStore } from 'context';
import OrderCard from 'components/Card/OrderCard';

const Order = () => {
  let history = useNavigate();
  const { setStep } = useCallStore();

  const { data, loading } = useQuery(GET_ORDERS);

  const addOrder = () => {
    setStep(1);
    history(PAGE_TECHNICAL_CONDITIONS);
  };

  if (loading)
    return (
      <div className="h-screen flex place-items-center justify-center ">
        <AiOutlineLoading3Quarters className="animate-spin  h-12 w-12 text-gray-500  " />
      </div>
    );

  return (
    <div className=" h-full bg-white  overflow-auto">
      <Header />
      <section className="pt-28 pb-0 px-0 max-w-3xl  mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
        <div className="flex px-3 sm:px-0 justify-between items-center">
          <h1 className=" text-base sm:text-xl font-semibold text-[#164789]">Захиалгын жагсаалт</h1>
          <div className="text-sm">
            <Button text="Ажил захиалах" onClick={addOrder} />
          </div>
        </div>
        <br />
        <div className="  bg-white p-2 pb-8">
          {data?.getOrders?.map((order: IOrder) => {
            return <OrderCard order={order} />;
          })}
        </div>
      </section>
    </div>
  );
};

export default Order;
