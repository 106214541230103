import React, { createContext, useCallback, useMemo } from 'react';
import decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { PAGE_INDEX } from 'constants/pages';

interface AuthContextType {
  authenticate: (token: string, cb: Function) => void;
  signOut: () => void;
}

interface Payload {
  sub: string;
  iss: string;
  exp: number;
}

export const getAccessToken = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('token');
  }
};

export const isValidToken = () => {
  const token = getAccessToken();
  if (!token) return false;
  try {
    const { exp }: Payload = decode(token);
    if (exp * 1000 < new Date().getTime()) return false;
    return true;
  } catch (e) {
    return false;
  }
};

export const getToken = () => {
  if (typeof window !== 'undefined') {
    if (isValidToken()) return getAccessToken();
  }
  return 'default-token';
};

export const getPayload = (): Payload | null => {
  const token = getAccessToken();
  if (!token) return null;
  try {
    const payload: Payload = decode(token);
    return payload;
  } catch (e) {
    return null;
  }
};

export const AuthContext = createContext({} as AuthContextType);

export const AuthProvider = ({ children }: any) => {
  let history = useNavigate();

  const authenticate = useCallback((token: string, cb: Function) => {
    localStorage.setItem('token', token);
    window.location.reload();
    if (cb) setTimeout(cb, 100);
  }, []);

  const signOut = useCallback(() => {
    localStorage.setItem('token', '');
    history('/');
  }, []);

  const defaultContext = useMemo<AuthContextType>(() => {
    return { authenticate, signOut };
  }, [authenticate, signOut]);

  return <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>;
};
