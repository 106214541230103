import { gql } from '@apollo/client';
export const GET_ORDERS = gql`
  {
    getOrders {
      id
      createdAt
      date
      number
      state
      pendingAt
      code
      tasks {
        id
        type
        state
        detailedPicture
        orderId
        workTypes
        documents {
          id
          name
          path
          input
        }
        offers {
          id
          createdAt
          price
          state
          partnerId
          types
          chargeAmount
        }
      }
      documents {
        id
        name
        path
        input
      }
    }
  }
`;

export const GET_ORDER = gql`
  query getOrder($id: ID!) {
    getOrder(id: $id) {
      id
      createdAt
      date
      code
      number
      state
      tasks {
        id
        type
        state
        detailedPicture
        orderId
        documents {
          id
          name
          path
          input
        }
      }
      documents {
        id
        name
        path
        input
      }
    }
  }
`;

export const GET_ORDER_BY_CODE = gql`
  query getOrderByCode($code: String!) {
    getOrderByCode(code: $code) {
      id
      createdAt
      date
      number
      state
      code
      tasks {
        id
        type
        state
        detailedPicture
        orderId
        workTypes
        documents {
          id
          name
          path
          input
          type
        }
      }
      documents {
        id
        name
        path
        input
        type
      }
    }
  }
`;
