import {
  PAGE_ABOUT,
  PAGE_ORDER,
  PAGE_ORDER_CONFIG,
  PAGE_ORDER_PROCESS,
  PAGE_TECHNICAL_CONDITIONS,
} from 'constants/pages';
import Home from './home';
import TechnicalConditions from './technical-conditions';
import Order from './order';
import OrderProcess from './order-process';
import OrderConfig from './order-config';
import Detail from './order/detail';
import About from 'pages/about';
import Charge from './order/charge';

export const privateRoutes = [
  {
    path: '/',
    component: <Home />,
  },
  {
    path: PAGE_TECHNICAL_CONDITIONS,
    component: <TechnicalConditions />,
  },
  {
    path: PAGE_ORDER,
    component: <Order />,
  },
  {
    path: PAGE_ABOUT,
    component: <About />,
  },
  {
    path: PAGE_ORDER + '/:code',
    component: <Detail />,
  },
  {
    path: PAGE_ORDER + '/charge' + '/:id',
    component: <Charge />,
  },
  {
    path: PAGE_ORDER_PROCESS + '/:code',
    component: <OrderProcess />,
  },
  {
    path: PAGE_ORDER_CONFIG + '/:code',
    component: <OrderConfig />,
  },
  {
    path: '*',
    component: <Home />,
  },
];
