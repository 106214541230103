import { MdDone } from 'react-icons/md';
import { ImSpinner2 } from 'react-icons/im';

type StepProgressProps = {
  currentStep: number;
};

const StepProgress = ({ currentStep }: StepProgressProps) => {
  return (
    <ol className="flex px-4 sm:px-14 items-center w-full text-xs text-gray-900 font-medium sm:text-base">
      <li
        className={`flex w-full relative   after:content-['']  after:w-full after:h-0.5  ${
          currentStep < 2 ? 'after:bg-gray-200 ' : 'after:bg-green-600'
        }   
        after:inline-block after:absolute lg:after:top-5 after:top-3 after:left-4`}
      >
        <div className="block whitespace-nowrap z-10">
          <span
            className={`w-6 h-6 ${
              1 > currentStep
                ? 'bg-gray-50  border-2 border-gray-200'
                : 'bg-green-600  border-transparent'
            }  border-2 rounded-full flex justify-center items-center mx-auto mb-3 text-sm text-white lg:w-10 lg:h-10`}
          >
            <MdDone className="text-xl" />
          </span>
          <div className=" left-[-28px] sm:left-[-44px]  w-[5rem] sm:w-[8rem] text-center absolute">
            <span className="text-xs text-gray-600 text-wrap">Техникийн нөхцөл</span>
          </div>
        </div>
      </li>

      <li
        className={`flex w-full relative   after:content-['']  after:w-full after:h-0.5  ${
          currentStep < 3 ? 'after:bg-gray-200 ' : 'after:bg-green-600'
        }   
    after:inline-block after:absolute lg:after:top-5 after:top-3 after:left-4`}
      >
        <div className="block whitespace-nowrap z-10">
          <span
            className={`w-6 h-6 ${
              2 > currentStep
                ? 'bg-gray-50  border-2 border-gray-200'
                : 'bg-green-600  border-transparent'
            }  border-2  rounded-full flex justify-center items-center mx-auto mb-3 text-sm text-white lg:w-10 lg:h-10`}
          >
            {currentStep < 2 ? (
              <>
                {currentStep === 1 && <ImSpinner2 className="text-xl animate-spin text-gray-500" />}
              </>
            ) : (
              <>
                <MdDone className="text-xl" />
              </>
            )}
          </span>

          <div className=" left-[-28px] sm:left-[-44px]   w-[5rem] sm:w-[8rem] text-center absolute">
            <span className="text-xs text-gray-600  text-wrap">Ажлын эзлэхүүн</span>
          </div>
        </div>
      </li>

      <li
        className={`flex w-full relative   after:content-['']  after:w-full after:h-0.5  ${
          currentStep < 4 ? 'after:bg-gray-200 ' : 'after:bg-green-600'
        }   
    after:inline-block after:absolute lg:after:top-5 after:top-3 after:left-4`}
      >
        <div className="block whitespace-nowrap z-10">
          <span
            className={`w-6 h-6 ${
              3 > currentStep
                ? 'bg-gray-50  border-2 border-gray-200'
                : 'bg-green-600  border-transparent'
            }  border-2  rounded-full flex justify-center items-center mx-auto mb-3 text-sm text-white lg:w-10 lg:h-10`}
          >
            {currentStep < 3 ? (
              <>
                {currentStep === 2 && <ImSpinner2 className="text-xl animate-spin text-gray-500" />}
              </>
            ) : (
              <MdDone className="text-xl" />
            )}
          </span>

          <div className=" left-[-28px] sm:left-[-44px]   w-[5rem] sm:w-[8rem] text-center absolute">
            <span className="text-xs text-gray-600  text-wrap">Үнийн санал</span>
          </div>
        </div>
      </li>
      <li className="flex  relative text-gray-900  ">
        <div className="block whitespace-nowrap z-10">
          <span
            className={`w-6 h-6 ${
              4 > currentStep
                ? 'bg-gray-50  border-2 border-gray-200'
                : 'bg-green-600  border-transparent'
            }  border-2  rounded-full flex justify-center items-center mx-auto mb-3 text-sm text-white lg:w-10 lg:h-10`}
          >
            {currentStep < 4 ? (
              <>
                {currentStep === 3 && <ImSpinner2 className="text-xl animate-spin text-gray-500" />}
              </>
            ) : (
              <MdDone className="text-xl" />
            )}
          </span>
          <div className=" left-[-28px] sm:left-[-44px] w-[5rem] sm:w-[8rem] text-center absolute">
            <span className="text-xs text-gray-600 text-wrap ">Ажлын гүйцэтгэл</span>
          </div>
        </div>
      </li>
    </ol>
  );
};

export default StepProgress;
