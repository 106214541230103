import { FieldValues, useForm } from 'react-hook-form';
import FormTemplate from 'templates/FormTemplate';
import { yupResolver } from '@hookform/resolvers/yup';
import ControlledAttachFile from 'components/ControlledForms/ControlledAttachFile';
import { technicalConditionSchema, validateButton } from 'components/resolvers';
import ControlledBank from 'components/ControlledForms/ControlledBank';
import { LuCheckCircle } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { PAGE_INDEX, PAGE_ORDER } from 'constants/pages';
import { CiWarning } from 'react-icons/ci';
import FormInputTemplate from 'templates/FormInputTemplate';
import { QrScanner } from '@yudiel/react-qr-scanner';
import Button from 'components/Button';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { extractUUID, generateRandomString } from 'tools';
import { ConvertImgUrl, formatWithCommas, uploadToS3 } from 'utils';
import { useMutation } from '@apollo/client';
import { CREATE_ORDER, PAY_ORDER, VALIDATE_TRANSACTION } from 'graphql/mutation';
import { useNotifications } from 'providers';
import { TECHNICAL_CONDITIONS } from 'constants/type';
import { isEmpty } from 'lodash';
import { useCallStore } from 'context';

const Index = () => {
  let history = useNavigate();
  const { addNotification } = useNotifications();
  const { order, setOrder, setTransaction, transaction, step, resetStore, setStep } =
    useCallStore();
  const [isShow, setIsShow] = useState(false);
  const [isError, setError] = useState(false);
  const [creating, setLoading] = useState(false);
  const { control, handleSubmit, watch, setValue } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(technicalConditionSchema(step)) as any,
    defaultValues: {
      step: step,
      files: [],
      term: false,
    },
  });

  useEffect(() => {
    if (step === 1) {
      resetStore();
    }
  }, [step, resetStore]);

  const { term, files, paymentType } = watch();

  const [createOrder, { loading }] = useMutation(CREATE_ORDER, {
    onCompleted: async (data) => {
      setOrder(data.createOrder);
      if (!isEmpty(files)) {
        for (const file of files) {
          const uploadUrl = data.createOrder.uploads.find((url: string) => {
            const normalizedUrl = decodeURIComponent(url)
              .toLowerCase()
              .replace(/[\s_]+/g, '');
            const normalizedFileName = file.name.toLowerCase().replace(/[\s_]+/g, '');
            return normalizedUrl.includes(normalizedFileName);
          });

          if (uploadUrl) {
            try {
              await uploadToS3(uploadUrl, file.content, file);
            } catch (uploadError) {
              addNotification(`Failed to upload ${file.name}`, 'error');
              console.error(`Error uploading file ${file.name}:`, uploadError);
            }
          } else {
            addNotification(`Upload URL not found for ${file.name}`, 'error');
          }
        }
      }
      setLoading(false);
      setStep(3);
    },
    onError: (error) => {
      setLoading(false);
      addNotification('Амжилтгүй', 'error');
    },
  });

  const [payOrder, { loading: paying }] = useMutation(PAY_ORDER, {
    onCompleted: async (data) => {
      let link = null;
      if (data.payOrder.transaction.links) {
        link = data.payOrder.transaction.links.find(
          (link: any) => link.name.toUpperCase() === paymentType.toUpperCase(),
        )?.link;
      }

      if (link) {
        window.location.href = link;
      }

      setTransaction(data.payOrder.transaction);
      setOrder(data.payOrder.order);
      setStep(4);
    },
    onError: (error) => {
      addNotification('Амжилтгүй', 'error');
    },
  });

  const [validateTransaction, { loading: validating }] = useMutation(VALIDATE_TRANSACTION, {
    onCompleted: async (data) => {
      if (data.validateTransaction) {
        setStep(5);
      } else {
        addNotification('Төлбөр төлөгдсөнгүй', 'error');
      }
    },
    onError: (error) => {
      addNotification('Амжилтгүй', 'error');
    },
  });

  const onSubmit = (data: FieldValues) => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setLoading(true);
      const tasks = {
        type: TECHNICAL_CONDITIONS,
        amount: 5,
        uploads: data.files.map((file: any) => file.name),
      };

      createOrder({
        variables: {
          input: {
            tasks: tasks,
          },
        },
      });
    } else if (step === 3) {
      payOrder({ variables: { id: order.id, purpose: 'T' } });
    } else if (step === 4) {
      if (transaction.state === 'PAID') {
        setStep(5);
      } else {
        addNotification('Төлбөр төлөгдсөнгүй', 'error');
      }
    } else if (step === 5) {
      resetStore();
      setStep(1);
      history(PAGE_ORDER);
    }
  };

  const onGoBack = () => {
    if (step === 4) {
      return setStep(3);
    }
    if (step === 3) {
      return setStep(2);
    }
    if (step === 2) {
      return setStep(1);
    }
    history(PAGE_INDEX);
  };

  const downloadPDF = async (url: string) => {
    let uuid = extractUUID(url);

    try {
      const response = await axios.get(`https://gerel.tog.mn/api/mc2081/tncheck?id=${uuid}`, {
        responseType: 'arraybuffer',
      });
      const pdfData = btoa(
        new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''),
      );
      const lastModified = response.data.byteLength;

      const dataURI = `data:application/pdf;base64,${pdfData}`;
      let randStr = generateRandomString();

      setValue('files', [
        ...files,
        {
          content: dataURI,
          lastModified: lastModified,
          name: randStr,
          path: randStr,
        },
      ]);
    } catch (error) {
      setError(true);
    }
  };

  const handleScan = (result: string | null) => {
    if (result) {
      downloadPDF(result);
      setError(false);
      setIsShow(false);
    }
  };

  const validate = () => {
    validateTransaction({ variables: { id: transaction.id } });
  };

  const renderFooter = (step: number) => {
    switch (step) {
      case 2:
        return (
          <div className="grid w-full ">
            <div className="w-full border-t md:border-0 border-gray-300 p-4 md:p-0  ">
              <div
                className="flex  gap-2 cursor-pointer border border-gray-200 mb-0 sm:mb-4  rounded-lg p-4 "
                onClick={() => setValue('term', !term)}
              >
                <div className="checkbox-wrapper-18">
                  <div className="round">
                    <input checked={term} type="checkbox" id="checkbox-18" />
                    <label htmlFor={'checkbox-18'}></label>
                  </div>
                </div>
                <span className="text-sm text-blue-800">
                  Үйлчилгээний нөхцөлийг зөвшөөрч байна.
                </span>
              </div>
            </div>

            <div className="flex gap-2 w-full   place-content-start ">
              <div className="w-full p-4 pt-0 sm:w-40 sm:p-0 text-center ">
                <Button
                  loading={loading || creating}
                  text="Үргэлжлүүлэх"
                  disabled={!term}
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="flex place-content-center w-full mt-4 border-t md:border-0 border-gray-300 p-4 md:p-0  ">
            <div className="w-full sm:w-40 text-center ">
              {transaction.state === 'PAID' ? (
                <Button
                  loading={loading}
                  text="Үргэлжлүүлэх"
                  disabled={validateButton(step, watch, transaction)}
                  onClick={handleSubmit(onSubmit)}
                />
              ) : (
                <Button loading={validating} text="Төлбөр шалгах" onClick={validate} />
              )}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <FormTemplate
      totalSteps={5}
      handleSubmit={handleSubmit}
      activeStep={step}
      onSubmit={onSubmit}
      onBack={() => onGoBack()}
      loading={loading || paying}
      hideLoginButton
      disabled={validateButton(step, watch, transaction)}
      renderFooter={renderFooter(step)}
    >
      <FormInputTemplate
        isHide={step === 1}
        formTypeName="Ажлын тоо хэмжээ"
        headerText="Техникийн нөхцөл"
        headerDescription="Техникийн нөхцөл дээрхи QR кодыг уншуулна уу. Эсвэл техникийн нөхцөлөө хавсаргана уу."
      >
        {isError && (
          <div
            className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            <svg
              className="flex-shrink-0 inline w-4 h-4 me-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <div>
              <span className="font-medium">QR-код буруу байна!</span>
            </div>
          </div>
        )}

        <div className="mb-6">
          <span
            onClick={() => setIsShow(!isShow)}
            className="flex hover:bg-[#164789] border bg-[#164789] text-white cursor-pointer text-sm text-center place-items-center w-full place-content-center py-2 rounded-lg px-3"
          >
            {isShow ? 'Камер хаах' : 'Камер нээх'}
          </span>
        </div>

        {isShow && (
          <div className="mb-6">
            <div className="p-4 mb-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50  ">
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 w-5 h-5 me-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="sr-only">Info</span>
                <span className="text-sm font-medium">
                  Та техникийн нөхцөл дээрхи QR код уншуулна уу.{' '}
                </span>
              </div>
            </div>
            <QrScanner
              scanDelay={300}
              constraints={{
                facingMode: 'environment',
              }}
              onError={(error: any) => console.log(error?.message)}
              onDecode={handleScan}
            />
          </div>
        )}
        <div className=" w-full divider divider-horizontal">
          <span className="text-sm text-gray-400">Эсвэл</span>
        </div>
        <ControlledAttachFile
          control={control}
          name="files"
          buttonText="Техникийн нөхцөл хавсаргах"
        />
      </FormInputTemplate>

      <FormInputTemplate isHide={step === 2} headerText="Үйлчилгээний нөхцөл">
        <div className="p-4 mb-4 text-blue-800 border place-items-start  border-blue-300 rounded-lg bg-blue-50  ">
          <div className="flex  gap-2 ">
            <div className=" h-5 text-center ">
              <svg
                className=" h-[19px] "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
            </div>

            <span className="text-sm font-medium">
              Энэхүү техникийн нөхцөлийн дагуу хийгдэх ажлын тоо хэмжээг урьдчилсан байдлаар
              гаргуулснаар системд бүртгэлтэй компаниуд таны захиалах ажилд үнийн санал өгөх
              боломжтой болох юм.
              <br />
              Урьдчилсан ажлын тоо хэмжээ гаргуулах уу?
              <br />
              <div className="w-full  items-center text-center mt-4">
                <span className=" font-semibold ">Төлбөр ₮ 100,000</span>
              </div>
            </span>
          </div>
        </div>
      </FormInputTemplate>

      <FormInputTemplate isHide={step === 3} headerText="Банк сонгох">
        <div className="w-full p-2 rounded-lg text-center bg-white border border-[#164789]">
          <span className="text-lg font-medium text-[#164789]">
            ₮{formatWithCommas(order?.totalAmount ?? 0)}
          </span>
        </div>
        <ControlledBank control={control} name="paymentType" />
      </FormInputTemplate>

      <FormInputTemplate isHide={step === 4}>
        <div className=" flex w-full  h-full  items-center text-center place-content-center">
          <div className="grid place-items-center ">
            <CiWarning className=" flex lg:hidden animate-pulse text-amber-400 h-20 w-20" />
            {transaction && (
              <img src={ConvertImgUrl(transaction)} alt="pay" className="hidden lg:flex" />
            )}
            <p className="font-medium mt-10 text-gray-700">Таны төлбөр төлөгдсөнөөр баталгаажна.</p>
          </div>
        </div>
      </FormInputTemplate>

      <FormInputTemplate isHide={step === 5}>
        <div className=" flex w-full min-h-40 mt-24 items-center text-center place-content-center">
          <div className="grid place-items-center ">
            <LuCheckCircle className=" animate-pulse text-green-500 h-20 w-20" />
            <p className="font-medium mt-10 text-gray-500">Таны төлбөр төлөгдлөө.</p>
            <p className="font-medium mt-2 text-gray-500 text-sm">
              Уг техникийн нөхцөлийн дагуу хийгдэх ажлын эзлэхүүн 72 цагийн дараа бэлэн болох ба
              таны бүртгэлтэй утас руу мэдэгдэх болно.
            </p>
          </div>
        </div>
      </FormInputTemplate>
    </FormTemplate>
  );
};

export default Index;
