import { gql } from '@apollo/client';
export const GET_TASK_OFFERS = gql`
  query getTaskOffers($id: ID!) {
    getTaskOffers(id: $id) {
      id
      createdAt
      price
      state
      partnerId
      types
      chargeAmount
    }
  }
`;

export const GET_TASK_OFFER = gql`
  query getOffer($id: ID!, $state: OfferState) {
    getOffer(id: $id, state: $state) {
      id
      createdAt
      price
      state
      partnerId
      chargeAmount
      types
    }
  }
`;
