import React from 'react';
import { Controller, Control, FieldError } from 'react-hook-form';
import FourDigitsMask from '../Mask/FourDigitsMask';

type Props = {
  control: Control<any, any>;
  name: any;
  loading: boolean;
};

const Index = ({ control, name, loading = false }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange }, formState: { errors } }) => {
        return (
          <div className="space-y-2">
            <FourDigitsMask loading={loading} setPin={(pin: string) => onChange(pin)} />
            <p className="mt-2 mb-2 text-red-500 text-xs">
              {errors && (errors[name] as FieldError)?.message}
            </p>
          </div>
        );
      }}
    />
  );
};

export default Index;
