export function generateRandomString() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `${result}.pdf`;
}

export function extractUUID(url: string) {
  const uuidRegex = /\/gerel\.tog\.mn\/tnshow\/([^/]+)/i;
  const match = url.match(uuidRegex);
  if (match && match[1]) {
    return match[1];
  } else {
    return null; // UUID not found in URL
  }
}
