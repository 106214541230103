import { create } from 'zustand';
import { persist, StateStorage } from 'zustand/middleware';
import { get, set, del } from 'idb-keyval';
import { IOrder, ITransaction } from 'types';

const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return (await get(name)) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    await set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    await del(name);
  },
};

// Define store interface
interface ICallStore {
  order: IOrder;
  step: number;
  transaction: ITransaction;
  authenticated: boolean | null;
  setAuthenticated: (authenticated: boolean) => void;
  setOrder: (order: IOrder) => void;
  setTransaction: (transaction?: ITransaction) => void;
  setStep: (step: number) => void;
  resetStore: () => void;
}

// Create store
export const useCallStore = create<ICallStore>(
  persist(
    (set) => ({
      order: null,
      step: 1,
      transaction: null,
      authenticated: null,
      setAuthenticated: (authenticated: boolean) => {
        set({ authenticated });
      },
      setOrder: (order: IOrder) => {
        set({ order });
      },
      setTransaction: (transaction?: ITransaction) => {
        set({ transaction });
      },
      setStep: (step: number) => {
        set({ step });
      },
      resetStore: async () => {
        set({
          step: 1,
          transaction: null,
          order: null,
        });
      },
    }),
    {
      name: 'call-storage',
      getStorage: () => storage,
    },
  ) as any,
);
