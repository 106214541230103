import { qpyBanks } from 'mocks';
import { ConvertQpayBankImg } from 'tools';

type Props = {
  paymentType: any;
  onSelect: (type: string) => void;
};

const Index = ({ paymentType, onSelect }: Props) => {
  return (
    <div className="w-full bg-white rounded-lg p-2 mt-4">
      <div className="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-6">
        {qpyBanks.map((bank, index) => (
          <div
            key={index}
            onClick={() => onSelect(bank.type)}
            className="rounded-lg flex place-self-center relative"
          >
            <img
              className={`w-16 rounded-lg ${
                paymentType === bank.type ? 'border-4 border-green-400' : ''
              }`}
              src={ConvertQpayBankImg(bank.type)}
              alt={`${bank.type} Bank`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Index;
