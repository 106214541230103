import { gql } from '@apollo/client';

export const REGISTER_PASSWORD = gql`
  mutation registerPassword($token: String!, $password: String!, $firstName: String!) {
    registerPassword(token: $token, password: $password, firstName: $firstName) {
      token
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($token: String!, $password: String!) {
    forgotPassword(token: $token, password: $password) {
      token
    }
  }
`;

export const LOGIN = gql`
  mutation login($phone: String!, $password: String!) {
    login(phone: $phone, password: $password) {
      token
    }
  }
`;
