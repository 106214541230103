import {
  PAGE_ABOUT,
  PAGE_AUTH,
  PAGE_FORGOT_PASSWORD,
  PAGE_LOGIN,
  PAGE_REGISTER,
  PAGE_USER_FLOW,
} from 'constants/pages';
import Login from './login';
import Home from './home';
import Register from './register';
import UserFlow from './user-flow';
import Auth from './auth';
import ForgotPassword from './forgot-password';
import About from 'pages/about';

export const publicRoutes = [
  {
    path: '/',
    component: <Home />,
  },
  {
    path: PAGE_LOGIN,
    component: <Login />,
  },

  {
    path: PAGE_REGISTER,
    component: <Register />,
  },

  {
    path: PAGE_FORGOT_PASSWORD,
    component: <ForgotPassword />,
  },

  {
    path: PAGE_USER_FLOW,
    component: <UserFlow />,
  },
  {
    path: PAGE_AUTH,
    component: <Auth />,
  },
  {
    path: PAGE_ABOUT,
    component: <About />,
  },
  {
    path: '*',
    component: <Auth />,
  },
];
