import { PAGE_AUTH, PAGE_ORDER, PAGE_REGISTER } from 'constants/pages';
import { AuthContext, getAccessToken } from 'providers';
import React, { useContext } from 'react';
import { CiUser } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';

const Avatar = () => {
  const token = getAccessToken();
  const { signOut } = useContext(AuthContext);

  let history = useNavigate();

  const goOrder = () => {
    history(PAGE_ORDER);
  };

  const onSignOut = () => {
    signOut();
    window.location.reload();
  };

  return (
    <>
      <div className="flex  justify-center">
        <div className="dropdown ">
          <div className="p-1  bg-gray-200 rounded-lg  ">
            <CiUser
              tabIndex={0}
              className=" h-6 w-6 text-[#164789]  rounded-lg focus:border-transparent focus:outline-none focus:ring-1 focus:ring-gray-200"
            />
          </div>
          <div className="dropdown-menu bg-white w-40">
            {token ? (
              <>
                <label htmlFor="modal-1">
                  <p className="dropdown-item text-sm  text-gray-800  hover:bg-gray-300 ">
                    Хувийн мэдээлэл
                  </p>
                </label>
                <p
                  className="dropdown-item text-sm text-gray-800  hover:bg-gray-300"
                  onClick={goOrder}
                >
                  Захиалга
                </p>
                <p
                  className="dropdown-item text-sm text-gray-800  hover:bg-gray-300"
                  onClick={() => onSignOut()}
                >
                  Гарах
                </p>
              </>
            ) : (
              <>
                <p
                  className="dropdown-item text-sm text-gray-800  hover:bg-gray-300 "
                  onClick={() => history(PAGE_AUTH)}
                >
                  Нэвтрэх
                </p>
                <p
                  onClick={() => history(PAGE_REGISTER)}
                  className="dropdown-item text-sm text-gray-800  hover:bg-gray-300"
                >
                  Бүртгүүлэх
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Avatar;
