import en from '../providers/i18n/en.json';
import mn from '../providers/i18n/mn.json';

export const LANG_RESOURCES = {
  en: {
    language: en, // 'common' is our custom namespace
  },
  mn: {
    language: mn,
  },
};
