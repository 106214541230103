import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseLoad from '../components/SuspenseLoad';
import { publicRoutes } from 'pages/public';
import { privateRoutes } from 'pages/private';
import { AuthContext, getAccessToken } from 'providers';
import { isEmpty } from 'lodash';

const Index = () => {
  const { signOut } = useContext(AuthContext);

  const token = getAccessToken();

  useEffect(() => {
    if (!token) {
      signOut();
    }
  }, [token, signOut]);

  const routesToRender = !isEmpty(token) ? privateRoutes : publicRoutes;

  return (
    <SuspenseLoad>
      <Routes>
        {routesToRender.map((option) => (
          <Route key={option.path} path={option.path} element={option.component} />
        ))}
      </Routes>
    </SuspenseLoad>
  );
};

export default Index;
