import { QPAY_BANK_TYPE } from 'constants/bank';

export const qpyBanks = [
  {
    type: QPAY_BANK_TYPE.MONPAY,
  },
  {
    type: QPAY_BANK_TYPE.SOCIAL_PAY,
  },
  {
    type: QPAY_BANK_TYPE.ARIG_BANK,
  },
  {
    type: QPAY_BANK_TYPE.MBANK,
  },
  {
    type: QPAY_BANK_TYPE.KHAAN_BANK,
  },
  {
    type: QPAY_BANK_TYPE.BOGD_BANK,
  },
  {
    type: QPAY_BANK_TYPE.CAPITRON_BANK,
  },
  {
    type: QPAY_BANK_TYPE.CHINGIG_KHAAN_BANK,
  },
  {
    type: QPAY_BANK_TYPE.MOST_MONEY,
  },

  {
    type: QPAY_BANK_TYPE.STATE_BANK,
  },
  {
    type: QPAY_BANK_TYPE.TRADE_AND_DEVELOPMENT_BANK,
  },
  {
    type: QPAY_BANK_TYPE.KHAS_BANK,
  },
];

export const certificateTypes = [
  {
    name: 'Зөвлөх инженерийн гэрчилгээ',
    value: '001',
  },
  {
    name: 'Мэргэшсэн инженерийн гэрчилгээ ',
    value: '002',
  },
];

export const certificateTypesName = [
  {
    name: 'Зөвлөх инженер',
    value: '001',
  },
  {
    name: 'Мэргэшсэн инженер',
    value: '002',
  },
];

export const publicationTypes = [
  {
    label: 'Цахилгаан угсралт',
    value: 'electricalInstallation',
  },
  {
    label: 'Ажлын зураг',
    value: 'workingDraw',
  },
  {
    label: 'Техникийн хяналт',
    value: 'technicalControl',
  },
];

export const workTypes = [
  {
    name: '0.4кВ-ын агаарын шугам',
    value: '2001',
  },
  {
    name: '0.4кВ-ын кабель шугам',
    value: '2002',
  },
  {
    name: '6-10/0.4кВ-ын дэд өртөө ХТП',
    value: '2003',
  },
  {
    name: '6-10/0.4кВ-ын иж бүрдмэл дэд өртөө КТПН',
    value: '2004',
  },
  {
    name: '35/04кВ-ын дэд өртөө ',
    value: '2005',
  },
  {
    name: '35/10кВ-ын дэд станц ',
    value: '2006',
  },
  {
    name: 'Хуваарилах байгууламж РП',
    value: '2007',
  },
];

export const purposeWorks = [
  {
    name: 'Ахуйн хэрэглээ',
    value: '1001',
  },
  {
    name: 'Үйлдвэр, үйлчилгээ барилгийн гадна цахилгаан хангамж',
    value: '1002',
  },
  {
    name: 'Орон сууцны барилгын гадна цахилгаан хангамж',
    value: '1003',
  },
  {
    name: 'Цэцэрлэгийн гадна цахилгаан хангамж',
    value: '1004',
  },
  {
    name: 'Эмнэлгийн гадна цахилгаан хангамж',
    value: '1005',
  },
  {
    name: 'Шугам, дэд станц зөөх',
    value: '1006',
  },
  {
    name: 'Нийтийн эзэмшлийн гэрэлтүүлэг',
    value: '1007',
  },
  {
    name: 'Барилгын түр тэжээл',
    value: '1008',
  },
  {
    name: 'Бусад',
    value: '1009',
  },
];

export const electricalInstallationPermit = [
  {
    name: 'ЭХЯ-ны ТЗ-2.1 0.4-15кВ-ын цахилгаан дамжуулах шугам, дэд станцын засвар, угсралт, түүний туршилт тохируулга, дагалдах үйлчилгээ    ',
    value: '4001',
  },
  {
    name: 'ЭХЯ-ны ТЗ-2.2 0.4-35кВ-ын цахилгаан дамжуулах шугам, дэд станцын засвар, угсралт, түүний туршилт тохируулга, дагалдах үйлчилгээ    ',
    value: '4002',
  },
  {
    name: 'ЭХЯ-ны ТЗ-2.3 0.4-110кВ-ын цахилгаан дамжуулах шугам, дэд станцын засвар, угсралт, түүний туршилт тохируулга, дагалдах үйлчилгээ    ',
    value: '4003',
  },
  {
    name: 'БХБЯ БА-3.5. Цахилгаан дамжуулах шугам сүлжээ, дэд станц, түүний тоног төхөөрөмжийн угсралт',
    value: '4004',
  },
  {
    name: 'БХБЯ-ны БА-4.5 Цахилгаан дамжуулах шугам сүлжээ, дэд станц, түүний тоног төхөөрөмжийн угсралт',
    value: '4005',
  },
  {
    name: 'БХБЯ-ны БА-5.5. Цахилгаан дамжуулах шугам сүлжээ, дэд станц, түүний тоног төхөөрөмжийн угсралт',
    value: '4005',
  },
];

export const workingDrawPermit = [
  {
    name: 'ЭХЯ-ны ТЗ-8 Эрчим хүчний барилга байгууламжийн зураг төсөл боловсруулах',
    value: '5001',
  },
  {
    name: 'БХБЯ-ны ЗТ-6.3 Барилга байгууламжийн дотор гэрэлтүүлэг, цахилгаан хангамжийн систем, 35кВ хүртэлх гадна салбар шугам сүлжээ, түүнтэй холбогдох барилга байгууламжийн технологийн зураг төсөл',
    value: '5002',
  },
];
