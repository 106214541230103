import { gql } from '@apollo/client';
export const GET_USER = gql`
  {
    getUser {
      id
      firstName
      name
      lastName
      phone
    }
  }
`;
