type Props = {
  children: React.ReactNode;
};

const Index = ({ children }: Props) => {
  return (
    <div className="flex flex-row">
      <div className=" basis-0 sm:basis-1/4 md:basis-1/6 lg:basis-1/6 xl:basis-1/8 2xl:basis-3/6"></div>
      <div className="w-full  px-4  sm:px-0  sm:basis-1/2 md:basis-8/12 lg:basis-8/12 xl:basis-8/12 2xl:basis-8/12">
        <div className="  cursor-pointer flex  w-full justify-between  mb-4">{children}</div>
      </div>
      <div className="basis-0 sm:basis-1/4  md:basis-1/6 lg:basis-1/6 xl:basis-1/8 2xl:basis-3/6"></div>
    </div>
  );
};

export default Index;
