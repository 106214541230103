import { FieldValues } from 'react-hook-form';
import * as yup from 'yup';

export const OrderProcessSchema = yup.object().shape({
  files: yup.array().when(['step', 'type'], (builder: FieldValues, schema, options) => {
    let step = options.parent.step;
    let type = options.parent.workingDraw;
    return Number(step) === 1
      ? type
        ? yup.array().notRequired()
        : yup
            .array()
            .min(1, 'Дор хаяж нэг файл шаардлагатай')
            .max(1, 'Зөвхөн нэг файл шаардлагатай')
            .of(
              yup.object().shape({
                name: yup.string().required('File name is required'),
              }),
            )
      : yup.array().notRequired();
  }),
});
