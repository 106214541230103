type Props = {
  children: React.ReactNode;
};

const Index = ({ children }: Props) => {
  return (
    <div className="w-full p-0  md:p-8  md:border mt-4 border-gray-300 rounded-xl ">{children}</div>
  );
};

export default Index;
