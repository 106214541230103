import React, { useEffect, useState } from 'react';
import moment from 'moment';

interface CountdownTimerProps {
  pendingAt: string; // ISO string
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ pendingAt }) => {
  const [timeLeft, setTimeLeft] = useState<string>('');

  useEffect(() => {
    const endTime = moment(pendingAt).add(72, 'hours');

    const updateTimer = () => {
      const now = moment();
      const duration = moment.duration(endTime.diff(now));

      if (duration.asSeconds() <= 0) {
        setTimeLeft('00:00:00');
        clearInterval(intervalId);
      } else {
        const hours = String(Math.floor(duration.asHours())).padStart(2, '0');
        const minutes = String(duration.minutes()).padStart(2, '0');
        const seconds = String(duration.seconds()).padStart(2, '0');
        setTimeLeft(`${hours}:${minutes}:${seconds}`);
      }
    };

    const intervalId = setInterval(updateTimer, 1000);
    updateTimer(); // Initial call to set the timer immediately

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [pendingAt]);

  return <div>{timeLeft}</div>;
};

export default CountdownTimer;
