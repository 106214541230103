import React from 'react';
import MainTemplate from 'templates/MainTemplates';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { PAGE_REGISTER, PAGE_SIGN_IN } from 'constants/pages';

const Index = () => {
  let history = useNavigate();

  const goRegister = () => {
    history(PAGE_REGISTER);
  };

  const goSignIn = () => {
    history(PAGE_SIGN_IN);
  };
  return (
    <MainTemplate>
      <div className="mb-2">
        <Button text="Нэвтрэх" onClick={goSignIn} isSolid isShadow />
      </div>
      <Button text="Бүртгүүлэх" onClick={goRegister} isShadow />
    </MainTemplate>
  );
};

export default Index;
