import React, { ReactNode, useEffect } from 'react';
import { useCallStore } from 'context/call.store';
import { getAccessToken, getPayload } from './auth';
import { IOrder, ITask, ITransaction } from 'types';
import { GET_TRANSACTION, GET_TRANSACTIONS } from 'graphql/query/transaction';
import { useQuery, useSubscription } from '@apollo/client';
import { ON_PAID_TRANSACTION, ON_UPDATED_ORDER } from 'graphql/subscription/order';
import { useNotifications } from './notification';
import { GET_ORDERS, GET_TASKS } from 'graphql/query';
import { ON_UPDATE_TASK } from 'graphql/subscription/task';

interface SubscriptionProviderProps {
  children: ReactNode;
}

const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({ children }) => {
  const { transaction, setTransaction, setStep } = useCallStore();
  const token = getAccessToken();
  const { addNotification } = useNotifications();
  const userId = getPayload()?.sub;

  const { data: transactionsData, loading: transactionsLoading } = useQuery(GET_TRANSACTIONS, {
    skip: !userId,
  });

  const { data: ordersData, loading: ordersLoading } = useQuery(GET_ORDERS, {
    skip: !userId,
  });

  useSubscription(ON_PAID_TRANSACTION, {
    variables: { user: userId },
    skip: !transaction,
    onData: ({ client, data }) => {
      if (!data) return;

      const { event, transaction: subscriptionTransaction } = data.data.onPaidTransaction;
      setTransaction(subscriptionTransaction);
      setStep(5);
      addNotification('Төлбөр амжилттай төлөгдлөө.', 'success');
      if (transactionsLoading) return;

      const existingTransactions =
        client.readQuery<{ getTransactions: ITransaction[] }>({
          query: GET_TRANSACTIONS,
        })?.getTransactions || [];

      const updatedTransactions = existingTransactions.map((transaction) =>
        transaction.id === subscriptionTransaction.id ? subscriptionTransaction : transaction,
      );

      if (
        event === 'UPDATED' &&
        !updatedTransactions.find((t) => t.id === subscriptionTransaction.id)
      ) {
        updatedTransactions.push(subscriptionTransaction);
      }

      client.writeQuery({
        query: GET_TRANSACTIONS,
        data: { getTransactions: updatedTransactions },
      });

      client.writeQuery({
        query: GET_TRANSACTION,
        variables: { id: subscriptionTransaction.id },
        data: { getTransaction: subscriptionTransaction },
      });
    },
    onError: (error) => {
      console.error('Subscription error:', error);
      addNotification('Төлбөрийн мэдээлэл шинэчлэхэд алдаа гарлаа.', 'error');
    },
  });

  useSubscription(ON_UPDATE_TASK, {
    variables: { user: userId },
    skip: !userId,
    onData: ({ client, data }) => {
      if (!data) return;

      const caches = client.readQuery<{ getTasks: ITask[] }>({ query: GET_TASKS });
      if (!caches?.getTasks) return;
      const { event, task: subscriptionTask } = data.data.onUpdateTask;
      let updateTasks = caches.getTasks.map((task) =>
        task.id === subscriptionTask.id ? subscriptionTask : task,
      );
      addNotification('Ажлын тоо хэмжээ бэлэн боллоо.', 'success');
      switch (event) {
        case 'CREATED':
        case 'UPDATED':
          updateTasks = updateTasks.filter((task) => task.id !== subscriptionTask.id);
          updateTasks.unshift(subscriptionTask);
          break;
        case 'DELETE':
          updateTasks = updateTasks.filter((task) => task.id !== subscriptionTask.id);
          break;
        default:
          return;
      }

      client.writeQuery({
        query: GET_TASKS,
        data: { getTasks: updateTasks },
      });
    },
  });

  useSubscription(ON_UPDATED_ORDER, {
    variables: { user: userId },
    skip: !userId,
    onData: ({ client, data }) => {
      if (!data) return;
      const caches = client.readQuery<{ getOrders: IOrder[] }>({ query: GET_ORDERS });
      if (!caches?.getOrders) return;

      const { event, order: subscriptionOrder } = data.data.onUpdatedOrder;
      let updatedOrders = caches.getOrders.map((order) =>
        order.id === subscriptionOrder.id ? subscriptionOrder : order,
      );
      switch (event) {
        case 'CREATED':
        case 'UPDATED':
          updatedOrders = updatedOrders.filter((order) => order.id !== subscriptionOrder.id);
          updatedOrders.unshift(subscriptionOrder);
          break;
        case 'DELETE':
          updatedOrders = updatedOrders.filter((order) => order.id !== subscriptionOrder.id);
          break;
        default:
          return;
      }

      client.writeQuery({
        query: GET_ORDERS,
        data: { getOrders: updatedOrders },
      });
    },
  });

  useEffect(() => {
    if (ordersLoading) return;
  }, [ordersLoading, ordersData]);

  useEffect(() => {
    if (transactionsLoading) return;
  }, [transactionsLoading, transactionsData]);

  return <>{children}</>;
};

export default SubscriptionProvider;
